import { all } from "redux-saga/effects";
import { watchAvailabilityRequests } from "./availability.sagas";
import { watchConnectionsRequests } from "./connections.sagas";
import { watchDirectApplyRequests } from "./direct-apply.sagas";
import { watchDirectApplyProgramRequests } from "./direct-apply-program.sagas";
import { watchEventsRequests } from "./events.sagas";
import { watchMiscRequests } from "./misc.sagas";
import { watchNotificationRequests } from "./notifications.sagas";
import { watchPageNotificationRequests } from "./page-notifications.sagas";
import { watchPartnerRequests } from "./partner.sagas";
import { watchScholAppointmentRequests } from "./school-appointment.sagas";
import { watchSchoolsRequests } from "./schools.sagas";
import { watchSessionsRequests } from "./sessions.sagas";
import { watchTeamRequests } from "./team.sagas";
import { watchUserRequests } from "./user.sagas";
import { watchWebinarRequests } from "./webinar.sagas";
import { watchDashboardRequests } from "./dashboard.sagas";
import { watchOnboardingRequests } from "./onboarding.sagas";

export function* rootSaga() {
  yield all([
    watchAvailabilityRequests(),
    watchDashboardRequests(),
    watchConnectionsRequests(),
    watchDirectApplyRequests(),
    watchDirectApplyProgramRequests(),
    watchEventsRequests(),
    watchMiscRequests(),
    watchNotificationRequests(),
    watchPageNotificationRequests(),
    watchPartnerRequests(),
    watchScholAppointmentRequests(),
    watchSchoolsRequests(),
    watchSessionsRequests(),
    watchTeamRequests(),
    watchUserRequests(),
    watchWebinarRequests(),
    watchOnboardingRequests(),
  ]);
}
