import { persistCombineReducers } from "redux-persist";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/es/storage";
import availabilityReducer from "./availability.reducer";
import directApplyReducer from "./direct-apply.reducer";
import directApplyFiltersReducer from "./direct-apply-filters.reducer";
import directApplyProgramsReducer from "./direct-apply-programs.reducer";
import directApplyProgramReducer from "./direct-apply-program.reducer";
import miscReducer from "./misc.reducer";
import notificationsReducer from "./notifications.reducer";
import pageNotificationsReducer from "./page-notifications.reducer";
import partnerReducer from "./partner.reducer";
import eventsReducer from "./events.reducer";
import schoolAppointmentReducer from "./school-appointment.reducer";
import schoolsReducer from "./schools.reducer";
import sessionsReducer from "./sessions.reducer";
import teamReducer from "./team.reducer";
import userReducer from "./user.reducer";
import webinarReducer from "./webinar.reducer";
import connectionsReducer from "./connections.reducer";
import loadingStateReducer from "./loading-state.reducer";
import dashboardReducer from "./dashboard.reducer";
import onboardingReducer from "./onboarding.reducer";

const config = {
  key: "cialfo_partners",
  storage,
  blacklist: [
    "form",
    "directApply",
    "directApplyPrograms",
    "directApplyProgram",
    "misc",
    // 'notifications',
    "connections",
    "availability",
    "dashboard",
    "schools",
    "sessions",
    "events",
    "team",
    "partner",
    "loadingState",
  ],
};

const rootReducer = persistCombineReducers(config, {
  availability: availabilityReducer,
  dashboard: dashboardReducer,
  directApply: directApplyReducer,
  directApplyListState: directApplyFiltersReducer,
  directApplyPrograms: directApplyProgramsReducer,
  directApplyProgram: directApplyProgramReducer,
  events: eventsReducer,
  form: formReducer,
  misc: miscReducer,
  notifications: notificationsReducer,
  pageNotifications: pageNotificationsReducer,
  partner: partnerReducer,
  schoolAppointment: schoolAppointmentReducer,
  schools: schoolsReducer,
  sessions: sessionsReducer,
  team: teamReducer,
  user: userReducer,
  webinar: webinarReducer,
  connections: connectionsReducer,
  loadingState: loadingStateReducer,
  onboarding: onboardingReducer,
});

export default rootReducer;
