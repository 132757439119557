/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React from "react";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { ADDED, APPLICANTS } from "src/screens/dashboard/constants";
import { DashboardCreators, DashboardTypes } from "../actions";
import { userSelector, dashboardSelector } from "./helpers";
import {
  getApplicationFunnelSummary,
  getSplitsConnectionInsights,
  getNewConnections,
  getNewActivities,
  getDashboardRegions,
  getDashboardSelectedRegions,
  getDashboardTrendChartSummary,
  getAddedTrendChartSummary,
  getApplicantsTrendChartSummary,
  getDashboardTrendChart,
  getAddedTrendChart,
  getInsightsTrendChart,
  getApplicantsTrendChart,
  getDashboardCountryWiseConnections,
  contactUem,
  getSplitsListing,
  getSplitsProfile,
  getSplitsEvents,
  getSplitsVisits,
  getRecommendedSchoolsToVisit,
  getDashboardEvents,
  getUserConfiguartion,
  updateUserConfiguartion,
  getUpcomingCalendlyEvents,
} from "../../web-services";

const COUNTRY = "country";

export function* watchDashboardRequests() {
  yield takeEvery(
    DashboardTypes.GET_APPLICATION_FUNNEL_SUMMARY_REQUEST,
    requestGetApplicationSummary,
  );
  yield takeEvery(
    DashboardTypes.GET_SPLITS_CONNECTION_INSIGHTS_REQUEST,
    requestGetSplitsConnectionInsights,
  );
  yield takeEvery(
    DashboardTypes.GET_SPLITS_LISTING_REQUEST,
    requestGetSplitsListing,
  );
  yield takeEvery(
    DashboardTypes.GET_SPLITS_PROFILE_REQUEST,
    requestGetSplitsProfile,
  );
  yield takeEvery(
    DashboardTypes.GET_SPLITS_EVENTS_REQUEST,
    requestGetSplitsEvents,
  );
  yield takeEvery(
    DashboardTypes.GET_SPLITS_VISITS_REQUEST,
    requestGetSplitsVisits,
  );
  yield takeEvery(
    DashboardTypes.GET_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST,
    requestRecommendedSchoolsToVisit,
  );
  yield takeEvery(
    DashboardTypes.GET_ADDED_TO_LIST_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST,
    requestAddedToListRecommendedSchoolsToVisit,
  );

  yield takeEvery(
    DashboardTypes.GET_APPLICANTS_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST,
    requestApplicantsRecommendedSchoolsToVisit,
  );

  yield takeEvery(
    DashboardTypes.GET_RECOMMENDED_SCHOOLS_TO_VISIT_PAST_REQUEST,
    requestRecommendedSchoolsToVisitPast,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_EVENTS_REQUEST,
    requestDashboardEvents,
  );
  yield takeEvery(
    DashboardTypes.GET_NEW_CONNECTIONS_REQUEST,
    requestGetNewConnections,
  );
  yield takeEvery(
    DashboardTypes.GET_ADDED_TO_LIST_NEW_ACTIVITIES_REQUEST,
    requestGetAddedToListNewActivities,
  );
  yield takeEvery(
    DashboardTypes.GET_APPLICANTS_NEW_ACTIVITIES_REQUEST,
    requestGetApplicantsNewActivities,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_REGIONS_REQUEST,
    requestGetDashboardRegions,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_SELECTED_REGIONS_REQUEST,
    requestGetDashboardSelectedRegions,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_TREND_CHART_SUMMARY_REQUEST,
    requestGetDashboardTrendChartSummary,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_TREND_CHART_REQUEST,
    requestGetDashboardTrendChart,
  );
  yield takeEvery(
    DashboardTypes.GET_ADDED_TREND_CHART_SUMMARY_REQUEST,
    requestGetAddedTrendChartSummary,
  );
  yield takeEvery(
    DashboardTypes.GET_ADDED_TREND_CHART_REQUEST,
    requestGetAddedTrendChart,
  );
  yield takeEvery(
    DashboardTypes.GET_INSIGHTS_TREND_CHART_REQUEST,
    requestGetInsightsTrendChart,
  );
  yield takeEvery(
    DashboardTypes.GET_APPLICANTS_TREND_CHART_SUMMARY_REQUEST,
    requestGetApplicantsTrendChartSummary,
  );
  yield takeEvery(
    DashboardTypes.GET_APPLICANTS_TREND_CHART_REQUEST,
    requestGetApplicantsTrendChart,
  );
  yield takeEvery(
    DashboardTypes.GET_DASHBOARD_COUNTRY_WISE_CONNECTIONS_REQUEST,
    requestGetDashboardCountryWiseConnections,
  );
  yield takeEvery(
    DashboardTypes.DASHBOARD_CONTACT_UEM_REQUEST,
    requestDashboardContactUem,
  );
  yield takeEvery(
    DashboardTypes.GET_USER_CONFIGURATION_REQUEST,
    requestUserConfiguration,
  );
  yield takeEvery(
    DashboardTypes.UPDATE_USER_CONFIGURATION_REQUEST,
    updateUserConfiguration,
  );
  yield takeEvery(
    DashboardTypes.GET_UPCOMING_CALENDLY_EVENTS_REQUEST,
    requestGetUpcomingCalendlyEvents,
  );
}

export function* requestGetApplicationSummary(action) {
  try {
    const { resolve } = action;
    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getApplicationFunnelSummary,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getApplicationFunnelSummarySuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getApplicationFunnelSummaryFailure());
    reject();
  }
}

export function* requestGetSplitsConnectionInsights(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getSplitsConnectionInsights,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getSplitsConnectionInsightsSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getSplitsConnectionInsightsFailure());
    reject();
  }
}

export function* requestGetSplitsListing(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getSplitsListing, authToken, data);

    yield put(
      DashboardCreators.getSplitsListingSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getSplitsListingFailure());
    reject();
  }
}

export function* requestGetSplitsProfile(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getSplitsProfile, authToken, data);

    yield put(
      DashboardCreators.getSplitsProfileSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getSplitsProfileFailure());
    reject();
  }
}

export function* requestGetSplitsEvents(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getSplitsEvents, authToken, data);

    yield put(
      DashboardCreators.getSplitsEventsSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getSplitsEventsFailure());
    reject();
  }
}

export function* requestGetSplitsVisits(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getSplitsVisits, authToken, data);

    yield put(
      DashboardCreators.getSplitsVisitsSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getSplitsVisitsFailure());
    reject();
  }
}

export function* requestRecommendedSchoolsToVisit(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      previous_visited: false,
    };

    const response = yield call(
      getRecommendedSchoolsToVisit,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getRecommendedSchoolsToVisitSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getRecommendedSchoolsToVisitFailure(),
    );
    reject();
  }
}

export function* requestAddedToListRecommendedSchoolsToVisit(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      previous_visited: false,
      states: ["shortlisted", "longlisted", "applying", "applied"],
    };

    const response = yield call(
      getRecommendedSchoolsToVisit,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getAddedToListRecommendedSchoolsToVisitSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getAddedToListRecommendedSchoolsToVisitFailure(),
    );
    reject();
  }
}

export function* requestApplicantsRecommendedSchoolsToVisit(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      previous_visited: false,
      states: ["applying", "applied"],
      included_states: ["shortlisted", "longlisted"],
    };

    const response = yield call(
      getRecommendedSchoolsToVisit,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getApplicantsRecommendedSchoolsToVisitSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getApplicantsRecommendedSchoolsToVisitFailure(),
    );
    reject();
  }
}

export function* requestRecommendedSchoolsToVisitPast(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      previous_visited: true,
    };

    const response = yield call(
      getRecommendedSchoolsToVisit,
      authToken,
      data,
    );

    yield put(
      DashboardCreators.getRecommendedSchoolsToVisitPastSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getRecommendedSchoolsToVisitPastFailure(),
    );
    reject();
  }
}

export function* requestDashboardEvents(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getDashboardEvents, authToken, data);

    yield put(
      DashboardCreators.getDashboardEventsSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getDashboardEventsFailure());
    reject();
  }
}

export function* requestGetNewConnections(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getNewConnections, authToken, data);

    yield put(
      DashboardCreators.getNewConnectionsSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getNewConnectionsFailure());
    reject();
  }
}

export function* requestGetAddedToListNewActivities(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      states: ["shortlisted", "longlisted", "applying", "applied"],
    };

    const response = yield call(getNewActivities, authToken, data);

    yield put(
      DashboardCreators.getAddedToListNewActivitiesSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getAddedToListNewActivitiesFailure());
    reject();
  }
}

export function* requestGetApplicantsNewActivities(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);

    const data = {
      country_ids: selectedCountryIds,
      states: ["applying", "applied"],
    };

    const response = yield call(getNewActivities, authToken, data);

    yield put(
      DashboardCreators.getApplicantsNewActivitiesSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getApplicantsNewActivitiesFailure());
    reject();
  }
}

export function* requestGetDashboardSelectedRegions(action) {
  try {
    const { resolve } = action;
    const { authToken } = yield select(userSelector);

    const response = yield call(
      getDashboardSelectedRegions,
      authToken,
    );
    yield put(
      DashboardCreators.getDashboardSelectedRegionsSuccess(
        response.data.country_ids,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getDashboardSelectedRegionsFailure());
    reject();
  }
}

export function* requestGetDashboardRegions(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);

    const response = yield call(getDashboardRegions, authToken);
    yield put(
      DashboardCreators.getDashboardRegionsSuccess(
        response.data.regions,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getDashboardRegionsFailure());
    reject();
  }
}

export function* requestGetDashboardTrendChartSummary(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getDashboardTrendChartSummary,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getDashboardTrendChartSummarySuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getDashboardTrendChartSummaryFailure(),
    );
    reject();
  }
}

export function* requestGetAddedTrendChartSummary(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getAddedTrendChartSummary,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getAddedTrendChartSummarySuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getAddedTrendChartSummaryFailure());
    reject();
  }
}

export function* requestGetApplicantsTrendChartSummary(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getApplicantsTrendChartSummary,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getApplicantsTrendChartSummarySuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getApplicantsTrendChartSummaryFailure(),
    );
    reject();
  }
}

export function* requestGetDashboardTrendChart(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getDashboardTrendChart,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getDashboardTrendChartSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getDashboardTrendChartFailure());
    reject();
  }
}

export function* requestGetAddedTrendChart(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(getAddedTrendChart, authToken, data);
    yield put(
      DashboardCreators.getAddedTrendChartSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getAddedTrendChartFailure());
    reject();
  }
}

export function* requestGetInsightsTrendChart(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getInsightsTrendChart,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getInsightsTrendChartSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getInsightsTrendChartFailure());
    reject();
  }
}

export function* requestGetApplicantsTrendChart(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      country_ids: selectedCountryIds,
    };

    const response = yield call(
      getApplicantsTrendChart,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getApplicantsTrendChartSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getApplicantsTrendChartFailure());
    reject();
  }
}

export function* requestGetDashboardCountryWiseConnections(action) {
  try {
    const { resolve, tab } = action;
    let states;
    if (tab === ADDED) {
      states = ["shortlisted", "longlisted", "applying", "applied"];
    } else if (tab === APPLICANTS) {
      states = ["applying", "applied"];
    }

    const { countryWiseOverview } = yield select(dashboardSelector);
    const { filterBy } = countryWiseOverview;
    const { authToken } = yield select(userSelector);
    const {
      regionFilter: { selectedCountryIds },
    } = yield select(dashboardSelector);
    const data = {
      states,
    };

    if (filterBy === COUNTRY) {
      data.country_ids = selectedCountryIds;
    } else {
      data.citizenship_ids = selectedCountryIds;
    }

    const response = yield call(
      getDashboardCountryWiseConnections,
      authToken,
      data,
    );
    yield put(
      DashboardCreators.getDashboardCountryWiseConnectionsSuccess(
        response.data,
      ),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(
      DashboardCreators.getDashboardCountryWiseConnectionsFailure(),
    );
    reject();
  }
}

export function* requestDashboardContactUem(action) {
  try {
    const { resolve, id, message, requestType } = action;

    const { authToken } = yield select(userSelector);

    const data = {
      request_type: requestType,
      comments: message,
    };

    yield call(contactUem, authToken, id, data);

    // AntdNotification(
    //   "info",
    //   "University Engagement Manager will reach out to you via email within 3 working days.",
    //   <FontAwesomeIcon
    //     icon={["fas", "check"]}
    //     className="text-lg text-white pt-1"
    //   />,
    // );
    yield put(DashboardCreators.dashboardContactUemSuccess());
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.dashboardContactUemFailure());
    reject();
  }
}

export function* requestUserConfiguration(action) {
  try {
    const { resolve } = action;

    const { authToken } = yield select(userSelector);

    const response = yield call(getUserConfiguartion, authToken);
    yield put(
      DashboardCreators.getUserConfigurationSuccess(response.data),
    );
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.getUserConfigurationFailure());
    reject();
  }
}

export function* updateUserConfiguration(action) {
  try {
    const { resolve, isDashboardOnboardingComplete } = action;

    const { authToken } = yield select(userSelector);

    yield call(
      updateUserConfiguartion,
      authToken,
      isDashboardOnboardingComplete,
    );
    yield put(DashboardCreators.updateUserConfigurationSuccess());
    resolve();
  } catch (error) {
    const { reject } = action;
    yield put(DashboardCreators.updateUserConfigurationFailure());
    reject();
  }
}

function* requestGetUpcomingCalendlyEvents() {
  try {
    const { authToken } = yield select(userSelector);
    const response = yield call(getUpcomingCalendlyEvents, authToken);
    yield put(
      DashboardCreators.getUpcomingCalendlyEventsSuccess(
        response.data.upcoming_events,
      ),
    );
  } catch (error) {
    yield put(DashboardCreators.getUpcomingCalendlyEventsFailure());
  }
}
