/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { createReducer } from "reduxsauce";
import { currentGraduationYear } from "src/constants";
import { DashboardTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.dashboard;

export const clearDashboard = () => INITIAL_STATE;

export const selectApplicationFunnelTab = (state, action) => ({
  ...state,
  applicationFunnel: {
    ...state.applicationFunnel,
    tab: action.tab,
  },
});

export const selectSplitsAndDimensionsTab = (state, { tab }) => {
  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      tab,
    },
  };
};

export const getSplitsConnectionInsightsRequest = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: true,
  },
});

export const getSplitsConnectionInsightsFailure = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: false,
  },
});

export const getSplitsConnectionInsightsSuccess = (state, action) => {
  const {
    data: {
      total_count,
      visits_count,
      events_count,
      profile_count,
      list_count,
    },
  } = action;

  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      loading: false,
      connectionsInsights: {
        total: total_count,
        visits: visits_count,
        events: events_count,
        profile: profile_count,
        addedToList: list_count,
      },
    },
  };
};

export const getSplitsListingRequest = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: true,
  },
});

export const getSplitsListingFailure = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: false,
  },
});

export const getSplitsListingSuccess = (state, action) => {
  const {
    data: { total_count, applying_list, short_list, long_list },
  } = action;

  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      loading: false,
      listing: {
        total: total_count,
        applyingList: applying_list,
        shortList: short_list,
        longList: long_list,
      },
    },
  };
};

export const getSplitsProfileRequest = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: true,
  },
});

export const getSplitsProfileFailure = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: false,
  },
});

export const getSplitsProfileSuccess = (state, action) => {
  const {
    data: { uniq_views, total_views, total_connections },
  } = action;

  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      loading: false,
      profile: {
        total: total_views,
        unique: uniq_views,
        connections: total_connections,
      },
    },
  };
};

export const getSplitsEventsRequest = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: true,
  },
});

export const getSplitsEventsFailure = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: false,
  },
});

export const getSplitsEventsSuccess = (state, action) => {
  const {
    data: { total_count, event_count },
  } = action;

  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      loading: false,
      events: {
        total: total_count,
        connections: event_count.map(({ event_name, count }) => ({
          legend: event_name,
          count,
          linkObj: {
            pathname: "/connections",
            state: {
              filter: {
                event_title: [{ id: event_name, text: event_name }],
                graduation_year: [
                  {
                    id: currentGraduationYear,
                    text: currentGraduationYear,
                  },
                ],
              },
            },
          },
        })),
      },
    },
  };
};

export const getSplitsVisitsRequest = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: true,
  },
});

export const getSplitsVisitsFailure = state => ({
  ...state,
  splitsAndDimensions: {
    ...state.splitsAndDimensions,
    loading: false,
  },
});

export const getSplitsVisitsSuccess = (state, action) => {
  const {
    data: { total_count, country_wise_count },
  } = action;
  const SIGN_UP_FOR_VISIT = "Signed up for visit";
  const SIGN_UP_FOR_THE_SCHOOL_VISIT = "Signup for the school visit";

  return {
    ...state,
    splitsAndDimensions: {
      ...state.splitsAndDimensions,
      loading: false,
      visits: {
        total: total_count,
        connections: country_wise_count.map(
          ({ id, name, count }) => ({
            id,
            legend: name,
            count,
            linkObj: {
              pathname: "/connections",
              state: {
                filter: {
                  country: [{ id, text: name }],
                  activity_type: [
                    {
                      id: SIGN_UP_FOR_VISIT,
                      text: [SIGN_UP_FOR_VISIT],
                    },
                    {
                      id: SIGN_UP_FOR_THE_SCHOOL_VISIT,
                      text: [SIGN_UP_FOR_THE_SCHOOL_VISIT],
                    },
                  ],
                  graduation_year: [
                    {
                      id: currentGraduationYear,
                      text: currentGraduationYear,
                    },
                  ],
                },
              },
            },
          }),
        ),
      },
    },
  };
};

export const getRecommendedSchoolsToVisitRequest = state => ({
  ...state,
  recommendedSchoolsToVisit: {
    ...state.recommendedSchoolsToVisit,
    loading: true,
  },
});

export const getRecommendedSchoolsToVisitFailure = state => ({
  ...state,
  recommendedSchoolsToVisit: {
    ...state.recommendedSchoolsToVisit,
    loading: false,
  },
});

export const getRecommendedSchoolsToVisitSuccess = (
  state,
  action,
) => {
  const {
    data: { schools },
  } = action;

  return {
    ...state,
    recommendedSchoolsToVisit: {
      ...state.recommendedSchoolsToVisit,
      loading: false,
      schools,
    },
  };
};

export const getAddedToListRecommendedSchoolsToVisitRequest =
  state => ({
    ...state,
    addedToListRecommendedSchoolsToVisit: {
      ...state.addedToListRecommendedSchoolsToVisit,
      loading: true,
    },
  });

export const getAddedToListRecommendedSchoolsToVisitFailure =
  state => ({
    ...state,
    addedToListRecommendedSchoolsToVisit: {
      ...state.addedToListRecommendedSchoolsToVisit,
      loading: false,
    },
  });

export const getAddedToListRecommendedSchoolsToVisitSuccess = (
  state,
  action,
) => {
  const {
    data: { schools },
  } = action;

  return {
    ...state,
    addedToListRecommendedSchoolsToVisit: {
      ...state.addedToListRecommendedSchoolsToVisit,
      loading: false,
      schools,
    },
  };
};

export const getApplicantsRecommendedSchoolsToVisitRequest =
  state => ({
    ...state,
    applicantsRecommendedSchoolsToVisit: {
      ...state.applicantsRecommendedSchoolsToVisit,
      loading: true,
    },
  });

export const getApplicantsRecommendedSchoolsToVisitFailure =
  state => ({
    ...state,
    applicantsRecommendedSchoolsToVisit: {
      ...state.applicantsRecommendedSchoolsToVisit,
      loading: false,
    },
  });

export const getApplicantsRecommendedSchoolsToVisitSuccess = (
  state,
  action,
) => {
  const {
    data: { schools },
  } = action;

  return {
    ...state,
    applicantsRecommendedSchoolsToVisit: {
      ...state.applicantsRecommendedSchoolsToVisit,
      loading: false,
      schools,
    },
  };
};

export const getRecommendedSchoolsToVisitPastRequest = state => ({
  ...state,
  recommendedSchoolsToVisit: {
    ...state.recommendedSchoolsToVisit,
    loadingPast: true,
  },
});

export const getRecommendedSchoolsToVisitPastFailure = state => ({
  ...state,
  recommendedSchoolsToVisit: {
    ...state.recommendedSchoolsToVisit,
    loaloadingPastding: false,
  },
});

export const getRecommendedSchoolsToVisitPastSuccess = (
  state,
  action,
) => {
  const {
    data: { schools },
  } = action;

  return {
    ...state,
    recommendedSchoolsToVisit: {
      ...state.recommendedSchoolsToVisit,
      loadingPast: false,
      schoolsPast: schools,
    },
  };
};

export const getDashboardEventsRequest = state => ({
  ...state,
  events: {
    ...state.events,
    loading: true,
  },
});

export const getDashboardEventsFailure = state => ({
  ...state,
  events: {
    ...state.events,
    loading: false,
  },
});

export const getDashboardEventsSuccess = (state, action) => {
  const { data } = action;

  return {
    ...state,
    events: {
      ...state.events,
      loading: false,
      events: data,
    },
  };
};

export const getNewConnectionsRequest = state => ({
  ...state,
  newConnections: {
    ...state.newConnections,
    loading: true,
  },
});

export const getNewConnectionsFailure = state => ({
  ...state,
  newConnections: {
    ...state.newConnections,
    loading: false,
  },
});

export const getNewConnectionsSuccess = (state, action) => {
  const {
    data: {
      total_connections_count,
      paid_connections_count,
      free_connections_count,
      connection_plan,
      connections,
      last_viewed,
    },
  } = action;

  return {
    ...state,
    newConnections: {
      ...state.newConnections,
      loading: false,
      total: total_connections_count,
      paid: paid_connections_count,
      free: free_connections_count,
      plan: connection_plan,
      lastViewed: last_viewed,
      connections: connections?.length
        ? connections.map(c => {
            let initials = "";
            if (c.first_name && c.last_name) {
              initials = c.first_name[0] + c.last_name[0];
            }
            return {
              ...c,
              initials,
            };
          })
        : [],
    },
  };
};

export const getAddedToListNewActivitiesRequest = state => ({
  ...state,
  addedToListNewActivities: {
    ...state.addedToListNewActivities,
    loading: true,
  },
});

export const getAddedToListNewActivitiesFailure = state => ({
  ...state,
  addedToListNewActivities: {
    ...state.addedToListNewActivities,
    loading: false,
  },
});

export const getAddedToListNewActivitiesSuccess = (state, action) => {
  const {
    data: {
      total_activities_count,
      paid_activities_count,
      free_activities_count,
      connection_plan,
      connections,
      last_viewed,
    },
  } = action;

  return {
    ...state,
    addedToListNewActivities: {
      ...state.addedToListNewActivities,
      loading: false,
      total: total_activities_count,
      paid: paid_activities_count,
      free: free_activities_count,
      plan: connection_plan,
      lastViewed: last_viewed,
      connections: connections?.length
        ? connections.map(c => {
            let initials = "";
            if (c.first_name && c.last_name) {
              initials = c.first_name[0] + c.last_name[0];
            }
            return {
              ...c,
              initials,
            };
          })
        : [],
    },
  };
};

export const getApplicantsNewActivitiesRequest = state => ({
  ...state,
  applicantsNewActivities: {
    ...state.applicantsNewActivities,
    loading: true,
  },
});

export const getApplicantsNewActivitiesFailure = state => ({
  ...state,
  applicantsNewActivities: {
    ...state.applicantsNewActivities,
    loading: false,
  },
});

export const getApplicantsNewActivitiesSuccess = (state, action) => {
  const {
    data: {
      total_activities_count,
      paid_activities_count,
      free_activities_count,
      connection_plan,
      connections,
      last_viewed,
    },
  } = action;

  return {
    ...state,
    applicantsNewActivities: {
      ...state.applicantsNewActivities,
      loading: false,
      total: total_activities_count,
      paid: paid_activities_count,
      free: free_activities_count,
      plan: connection_plan,
      lastViewed: last_viewed,
      connections: connections?.length
        ? connections.map(c => {
            let initials = "";
            if (c.first_name && c.last_name) {
              initials = c.first_name[0] + c.last_name[0];
            }
            return {
              ...c,
              initials,
            };
          })
        : [],
    },
  };
};

export const getApplicationFunnelSummaryRequest = state => ({
  ...state,
  applicationFunnel: {
    ...state.applicationFunnel,
    loading: true,
  },
});

export const getApplicationFunnelSummaryFailure = state => ({
  ...state,
  applicationFunnel: {
    ...state.applicationFunnel,
    loading: false,
  },
});

export const getApplicationFunnelSummarySuccess = (state, action) => {
  const {
    data: {
      connected,
      applying,
      added_to_list,
      free_connections_count,
      paid_connections_count,
    },
  } = action;

  return {
    ...state,
    applicationFunnel: {
      ...state.applicationFunnel,
      loading: false,
      connected,
      applying,
      addedToList: added_to_list,
      freeConnectionsCount: free_connections_count,
      paidConnectionsCount: paid_connections_count,
    },
  };
};

export const dashboardContactUemRequest = state => ({
  ...state,
});

export const dashboardContactUemFailure = state => ({
  ...state,
});

export const dashboardContactUemSuccess = state => ({
  ...state,
});

export const getDashboardSelectedRegionsFailure = state => ({
  ...state,
});

export const getDashboardSelectedRegionsRequest = state => ({
  ...state,
});

export const getDashboardSelectedRegionsSuccess = (state, action) => {
  const { selected_regions } = action;
  const {
    regionFilter: { regionsState },
  } = state;

  const updatedRegions = regionsState.map(region => {
    const updatedCountries = region.countries.map(country => ({
      ...country,
      selected: selected_regions.includes(country.id),
    }));

    const selectedCount = updatedCountries.filter(
      c => c.selected,
    ).length;

    return {
      ...region,
      countries: updatedCountries,
      selectedCountries: selectedCount,
      selected: selectedCount > 0,
    };
  });

  const selectedFilterBadges = updatedRegions
    .filter(region => region.selected)
    .map(region => {
      const { name, id, totalCountries, selectedCountries } = region;
      return {
        name,
        id,
        countryExcluded: totalCountries - selectedCountries,
      };
    });

  return {
    ...state,
    selectedFilterBadges,
    regionFilter: {
      ...state.regionFilter,
      regionsState: updatedRegions,
      selectedCountryIds: selected_regions,
    },
  };
};

export const getDashboardRegionsFailure = state => ({
  ...state,
});

export const getDashboardRegionsRequest = state => ({
  ...state,
});

const initializeCountriesChecklist = countries =>
  countries.map(country => ({
    ...country,
    selected: false,
  }));

export const getDashboardRegionsSuccess = (state, action) => {
  const { regions } = action;
  const stateAddedRegions = regions.map(region => ({
    ...region,
    expand: false,
    selected: false,
    totalCountries: region.countries.length,
    selectedCountries: 0,
    countries: initializeCountriesChecklist(region.countries),
  }));
  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regions,
      regionsState: stateAddedRegions,
      filteredRegionState: stateAddedRegions,
    },
  };
};

export const getUserConfigurationFailure = state => ({
  ...state,
});

export const getUserConfigurationRequest = state => ({
  ...state,
});

export const getUserConfigurationSuccess = (state, action) => {
  const { config } = action;

  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      isOnboardingDone: config.is_dashboard_onboarding_complete,
      visits_feedback_pending: config.visits_feedback_pending,
      has_leads_available: config.has_leads_available,
    },
  };
};

export const updateUserConfigurationFailure = state => ({
  ...state,
});

export const updateUserConfigurationRequest = (state, action) => {
  const { isDashboardOnboardingComplete } = action;
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      isOnboardingDone: isDashboardOnboardingComplete,
      step: isDashboardOnboardingComplete ? state.onboarding.step : 1,
    },
  };
};

export const updateUserConfigurationSuccess = state => ({
  ...state,
});

export const getDashboardTrendChartSummaryFailure = state => ({
  ...state,
  trendChart: {
    ...state.trendChart,
    loading: false,
  },
});

export const getDashboardTrendChartSummaryRequest = state => ({
  ...state,
  trendChart: {
    ...state.trendChart,
    loading: true,
  },
});

export const getDashboardTrendChartSummarySuccess = (
  state,
  action,
) => {
  const { trendChartSummary } = action;
  const {
    connected_count,
    counsellors_count,
    guardian_count,
    international_students_count,
  } = trendChartSummary;

  const trendChart = {
    connectedCount: connected_count,
    counsellorsCount: counsellors_count,
    guardianCount: guardian_count,
    internationalStudentsCount: international_students_count,
  };

  return {
    ...state,
    trendChart: {
      ...state.trendChart,
      loading: false,
      ...trendChart,
    },
  };
};

export const getAddedTrendChartSummaryFailure = state => ({
  ...state,
  addedTrendChart: {
    ...state.addedTrendChart,
    loading: false,
  },
});

export const getAddedTrendChartSummaryRequest = state => ({
  ...state,
  addedTrendChart: {
    ...state.addedTrendChart,
    loading: true,
  },
});

export const getAddedTrendChartSummarySuccess = (state, action) => {
  const { trendChartSummary } = action;
  const { added_to_list_count, international_students_count } =
    trendChartSummary;

  const addedTrendChart = {
    addedToListCount: added_to_list_count,
    internationalStudentsCount: international_students_count,
  };

  return {
    ...state,
    addedTrendChart: {
      ...state.addedTrendChart,
      loading: false,
      ...addedTrendChart,
    },
  };
};

export const getApplicantsTrendChartSummaryFailure = state => ({
  ...state,
  applicantsTrendChart: {
    ...state.applicantsTrendChart,
    loading: false,
  },
});

export const getApplicantsTrendChartSummaryRequest = state => ({
  ...state,
  applicantsTrendChart: {
    ...state.applicantsTrendChart,
    loading: true,
  },
});

export const getApplicantsTrendChartSummarySuccess = (
  state,
  action,
) => {
  const { trendChartSummary } = action;
  const {
    applying_count,
    international_count,
    submitted_count,
    direct_app_count,
  } = trendChartSummary;

  const applicantsTrendChart = {
    applyingCount: applying_count,
    internationalCount: international_count,
    submittedCount: submitted_count,
    directAppCount: direct_app_count,
  };

  return {
    ...state,
    applicantsTrendChart: {
      ...state.applicantsTrendChart,
      loading: false,
      ...applicantsTrendChart,
    },
  };
};

export const getDashboardTrendChartFailure = state => ({
  ...state,
  trendChart: {
    ...state.trendChart,
    loading: false,
  },
});

export const getDashboardTrendChartRequest = state => ({
  ...state,
  trendChart: {
    ...state.trendChart,
    loading: true,
  },
});

const getTrendChartData = trendChartDetails => {
  const [lastYear, currentYear] = Object.keys(trendChartDetails);
  const [lastYearData, currentYearData] =
    Object.values(trendChartDetails);

  const lastYearMonthlyData = lastYearData.map(t => t.total);
  const currentYearMonthlyData = currentYearData.map(t => t.total);
  const lastYearCumulativeData = lastYearData.map(t => t.cumulative);
  const currentYearCumulativeData = currentYearData.map(
    t => t.cumulative,
  );

  return {
    currentYearMonthlyData,
    lastYearMonthlyData,
    currentYearCumulativeData,
    lastYearCumulativeData,
    lastYear,
    currentYear,
  };
};

export const getDashboardTrendChartSuccess = (state, action) => {
  const { trendChartDetails } = action;

  const trendData = getTrendChartData(trendChartDetails);

  return {
    ...state,
    trendChart: {
      ...state.trendChart,
      loading: false,
      ...trendData,
    },
  };
};

export const getAddedTrendChartFailure = state => ({
  ...state,
  addedTrendChart: {
    ...state.addedTrendChart,
    loading: false,
  },
});

export const getAddedTrendChartRequest = state => ({
  ...state,
  addedTrendChart: {
    ...state.addedTrendChart,
    loading: true,
  },
});

export const getAddedTrendChartSuccess = (state, action) => {
  const { trendChartDetails } = action;

  const trendData = getTrendChartData(trendChartDetails);

  return {
    ...state,
    addedTrendChart: {
      ...state.addedTrendChart,
      loading: false,
      ...trendData,
    },
  };
};

export const getInsightsTrendChartFailure = state => ({
  ...state,
  insightsTrendChart: {
    ...state.insightsTrendChart,
    loading: false,
  },
});

export const getInsightsTrendChartRequest = state => ({
  ...state,
  insightsTrendChart: {
    ...state.insightsTrendChart,
    loading: true,
  },
});

export const getInsightsTrendChartSuccess = (state, action) => {
  const { trendChartDetails } = action;
  const { applying, longlisted, shortlisted } = trendChartDetails;

  const applyingListMonthlyData = applying.map(t => t.total);
  const applyingListCumulativeData = applying.map(t => t.cumulative);
  const longListMonthlyData = longlisted.map(t => t.total);
  const longListCumulativeData = longlisted.map(t => t.cumulative);
  const shortListMonthlyData = shortlisted.map(t => t.total);
  const shortListCumulativeData = shortlisted.map(t => t.cumulative);

  return {
    ...state,
    insightsTrendChart: {
      ...state.insightsTrendChart,
      loading: false,
      applyingListMonthlyData,
      applyingListCumulativeData,
      longListMonthlyData,
      longListCumulativeData,
      shortListMonthlyData,
      shortListCumulativeData,
    },
  };
};

export const getApplicantsTrendChartFailure = state => ({
  ...state,
  applicantsTrendChart: {
    ...state.applicantsTrendChart,
    loading: false,
  },
});

export const getApplicantsTrendChartRequest = state => ({
  ...state,
  applicantsTrendChart: {
    ...state.applicantsTrendChart,
    loading: true,
  },
});

export const getApplicantsTrendChartSuccess = (state, action) => {
  const { trendChartDetails } = action;

  const trendData = getTrendChartData(trendChartDetails);

  return {
    ...state,
    applicantsTrendChart: {
      ...state.applicantsTrendChart,
      loading: false,
      ...trendData,
    },
  };
};

export const getDashboardCountryWiseConnectionsFailure = state => ({
  ...state,
  countryWiseOverview: {
    ...state.countryWiseOverview,
    loading: false,
  },
});

export const getDashboardCountryWiseConnectionsRequest = state => ({
  ...state,
  countryWiseOverview: {
    ...state.countryWiseOverview,
    loading: true,
  },
});

export const getDashboardCountryWiseConnectionsSuccess = (
  state,
  action,
) => {
  const { connections } = action;
  const { country_wise_count, total_count } = connections;

  const countryWiseConnections = country_wise_count.map(
    ({ name: legend, ...rest }) => ({
      legend,
      ...rest,
    }),
  );

  return {
    ...state,
    countryWiseOverview: {
      ...state.countryWiseOverview,
      loading: false,
      totalConnections: total_count,
      countryWiseConnections,
    },
  };
};

export const applyDashboardFiltersFailure = state => ({
  ...state,
});

export const applyDashboardFiltersRequest = state => {
  const {
    regionFilter: { regionsState },
  } = state;
  const selectedFilterBadges = regionsState
    .filter(region => region.selected)
    .map(region => {
      const { name, id, totalCountries, selectedCountries } = region;
      return {
        name,
        id,
        countryExcluded: totalCountries - selectedCountries,
      };
    });

  const countryIds = regionsState
    .filter(region => region.selected)
    .map(region =>
      region.countries
        .filter(country => country.selected)
        .map(country => country.id),
    );

  const selectedCountryIds = countryIds.flat(1);

  return {
    ...state,
    selectedFilterBadges,
    regionFilter: {
      ...state.regionFilter,
      filteredRegionState: regionsState,
      selectedCountryIds,
    },
  };
};

export const resetDashboardFiltersFailure = state => ({
  ...state,
});

export const resetDashboardFiltersRequest = state => {
  const {
    regionFilter: { regions },
  } = state;
  const defaultStateRegions = regions.map(region => ({
    ...region,
    expand: false,
    selected: false,
    totalCountries: region.countries.length,
    selectedCountries: 0,
    countries: initializeCountriesChecklist(region.countries),
  }));

  return {
    ...state,
    selectedFilterBadges: [],
    regionFilter: {
      ...state.regionFilter,
      regionsState: defaultStateRegions,
      filteredRegionState: defaultStateRegions,
      selectedCountryIds: [],
    },
  };
};

export const toggleDashboardRegionExpand = (state, action) => {
  const { id } = action;
  const {
    regionFilter: { regionsState },
  } = state;

  const updatedRegionsState = regionsState.map(region =>
    region.id === id ? { ...region, expand: !region.expand } : region,
  );

  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regionsState: updatedRegionsState,
    },
  };
};

export const setDashboardRegionCheckbox = (state, action) => {
  const { id } = action;
  const {
    regionFilter: { regionsState },
  } = state;

  const updatedRegionsState = regionsState.map(region => {
    const { id: regionId, selected, countries } = region;

    if (regionId !== id) {
      return region;
    }

    return {
      ...region,
      selected: !selected,
      selectedCountries: !selected ? countries.length : 0,
      countries: countries.map(country => ({
        ...country,
        selected: !selected,
      })),
    };
  });

  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regionsState: updatedRegionsState,
    },
  };
};

export const setDashboardCountryCheckbox = (state, action) => {
  const { regionId, countryId, isSelected } = action;
  const {
    regionFilter: { regionsState },
  } = state;

  const updatedRegionsState = regionsState.map(region => {
    const { id, selectedCountries, selected, countries } = region;

    if (id !== regionId) {
      return region;
    }

    const isRegionCheckboxSelected = () => {
      if (isSelected && selectedCountries === 0) {
        return isSelected;
      }
      if (selectedCountries === 1 && !isSelected) {
        return false;
      }
      return selected;
    };

    return {
      ...region,
      selectedCountries: isSelected
        ? selectedCountries + 1
        : selectedCountries - 1,
      selected: isRegionCheckboxSelected(),
      countries: countries.map(country =>
        country.id === countryId
          ? { ...country, selected: !country.selected }
          : country,
      ),
    };
  });

  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regionsState: updatedRegionsState,
    },
  };
};

export const dashboardFilterBadgeClick = (state, action) => {
  const { id } = action;
  const {
    regionFilter: { regionsState },
  } = state;

  const updatedRegionsState = regionsState.map(region =>
    region.id === id ? { ...region, expand: true } : region,
  );

  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regionsState: updatedRegionsState,
    },
  };
};

export const setDashboardLastFilteredRegionState = state => {
  const {
    regionFilter: { filteredRegionState },
  } = state;
  return {
    ...state,
    regionFilter: {
      ...state.regionFilter,
      regionsState: filteredRegionState,
    },
  };
};

export const setDashboardCountryWiseConnectionFilter = (
  state,
  action,
) => {
  const { filterBy } = action;

  return {
    ...state,
    countryWiseOverview: {
      ...state.countryWiseOverview,
      filterBy,
    },
  };
};

export const updateDashboardOnboardingStep = (state, action) => {
  const { stepAction } = action;

  const {
    onboarding: { step },
  } = state;
  return {
    ...state,
    onboarding: {
      ...state.onboarding,
      step: stepAction === "next" ? step + 1 : step - 1,
    },
  };
};

export const getUpcomingCalendlyEventsFailure = state => ({
  ...state,
});
export const getUpcomingCalendlyEventsRequest = state => ({
  ...state,
});
export const getUpcomingCalendlyEventsSuccess = (state, action) => {
  return {
    ...state,
    calendlyEvents: action.calendlyEvents,
  };
};

export const HANDLERS = {
  [Types.CLEAR_DASHBOARD]: clearDashboard,

  [Types.SELECT_APPLICATION_FUNNEL_TAB]: selectApplicationFunnelTab,
  [Types.SELECT_SPLITS_AND_DIMENSIONS_TAB]:
    selectSplitsAndDimensionsTab,
  [Types.GET_APPLICATION_FUNNEL_SUMMARY_REQUEST]:
    getApplicationFunnelSummaryRequest,
  [Types.GET_APPLICATION_FUNNEL_SUMMARY_SUCCESS]:
    getApplicationFunnelSummarySuccess,
  [Types.GET_APPLICATION_FUNNEL_SUMMARY_FAILURE]:
    getApplicationFunnelSummaryFailure,

  [Types.GET_SPLITS_CONNECTION_INSIGHTS_REQUEST]:
    getSplitsConnectionInsightsRequest,
  [Types.GET_SPLITS_CONNECTION_INSIGHTS_FAILURE]:
    getSplitsConnectionInsightsFailure,
  [Types.GET_SPLITS_CONNECTION_INSIGHTS_SUCCESS]:
    getSplitsConnectionInsightsSuccess,

  [Types.GET_SPLITS_LISTING_REQUEST]: getSplitsListingRequest,
  [Types.GET_SPLITS_LISTING_FAILURE]: getSplitsListingFailure,
  [Types.GET_SPLITS_LISTING_SUCCESS]: getSplitsListingSuccess,

  [Types.GET_SPLITS_PROFILE_REQUEST]: getSplitsProfileRequest,
  [Types.GET_SPLITS_PROFILE_FAILURE]: getSplitsProfileFailure,
  [Types.GET_SPLITS_PROFILE_SUCCESS]: getSplitsProfileSuccess,

  [Types.GET_SPLITS_EVENTS_REQUEST]: getSplitsEventsRequest,
  [Types.GET_SPLITS_EVENTS_FAILURE]: getSplitsEventsFailure,
  [Types.GET_SPLITS_EVENTS_SUCCESS]: getSplitsEventsSuccess,

  [Types.GET_SPLITS_VISITS_REQUEST]: getSplitsVisitsRequest,
  [Types.GET_SPLITS_VISITS_FAILURE]: getSplitsVisitsFailure,
  [Types.GET_SPLITS_VISITS_SUCCESS]: getSplitsVisitsSuccess,

  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST]:
    getRecommendedSchoolsToVisitRequest,
  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_FAILURE]:
    getRecommendedSchoolsToVisitFailure,
  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_SUCCESS]:
    getRecommendedSchoolsToVisitSuccess,

  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_PAST_REQUEST]:
    getRecommendedSchoolsToVisitPastRequest,
  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_PAST_FAILURE]:
    getRecommendedSchoolsToVisitPastFailure,
  [Types.GET_RECOMMENDED_SCHOOLS_TO_VISIT_PAST_SUCCESS]:
    getRecommendedSchoolsToVisitPastSuccess,

  [Types.GET_ADDED_TO_LIST_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST]:
    getAddedToListRecommendedSchoolsToVisitRequest,
  [Types.GET_ADDED_TO_LIST_RECOMMENDED_SCHOOLS_TO_VISIT_FAILURE]:
    getAddedToListRecommendedSchoolsToVisitFailure,
  [Types.GET_ADDED_TO_LIST_RECOMMENDED_SCHOOLS_TO_VISIT_SUCCESS]:
    getAddedToListRecommendedSchoolsToVisitSuccess,

  [Types.GET_APPLICANTS_RECOMMENDED_SCHOOLS_TO_VISIT_REQUEST]:
    getApplicantsRecommendedSchoolsToVisitRequest,
  [Types.GET_APPLICANTS_RECOMMENDED_SCHOOLS_TO_VISIT_FAILURE]:
    getApplicantsRecommendedSchoolsToVisitFailure,
  [Types.GET_APPLICANTS_RECOMMENDED_SCHOOLS_TO_VISIT_SUCCESS]:
    getApplicantsRecommendedSchoolsToVisitSuccess,

  [Types.GET_DASHBOARD_EVENTS_REQUEST]: getDashboardEventsRequest,
  [Types.GET_DASHBOARD_EVENTS_FAILURE]: getDashboardEventsFailure,
  [Types.GET_DASHBOARD_EVENTS_SUCCESS]: getDashboardEventsSuccess,

  [Types.GET_NEW_CONNECTIONS_REQUEST]: getNewConnectionsRequest,
  [Types.GET_NEW_CONNECTIONS_FAILURE]: getNewConnectionsFailure,
  [Types.GET_NEW_CONNECTIONS_SUCCESS]: getNewConnectionsSuccess,

  [Types.GET_ADDED_TO_LIST_NEW_ACTIVITIES_REQUEST]:
    getAddedToListNewActivitiesRequest,
  [Types.GET_ADDED_TO_LIST_NEW_ACTIVITIES_FAILURE]:
    getAddedToListNewActivitiesFailure,
  [Types.GET_ADDED_TO_LIST_NEW_ACTIVITIES_SUCCESS]:
    getAddedToListNewActivitiesSuccess,

  [Types.GET_APPLICANTS_NEW_ACTIVITIES_REQUEST]:
    getApplicantsNewActivitiesRequest,
  [Types.GET_APPLICANTS_NEW_ACTIVITIES_FAILURE]:
    getApplicantsNewActivitiesFailure,
  [Types.GET_APPLICANTS_NEW_ACTIVITIES_SUCCESS]:
    getApplicantsNewActivitiesSuccess,

  [Types.GET_DASHBOARD_REGIONS_FAILURE]: getDashboardRegionsFailure,
  [Types.GET_DASHBOARD_REGIONS_REQUEST]: getDashboardRegionsRequest,
  [Types.GET_DASHBOARD_REGIONS_SUCCESS]: getDashboardRegionsSuccess,

  [Types.GET_DASHBOARD_SELECTED_REGIONS_FAILURE]:
    getDashboardSelectedRegionsFailure,
  [Types.GET_DASHBOARD_SELECTED_REGIONS_REQUEST]:
    getDashboardSelectedRegionsRequest,
  [Types.GET_DASHBOARD_SELECTED_REGIONS_SUCCESS]:
    getDashboardSelectedRegionsSuccess,

  [Types.GET_ADDED_TREND_CHART_SUMMARY_FAILURE]:
    getAddedTrendChartSummaryFailure,
  [Types.GET_ADDED_TREND_CHART_SUMMARY_REQUEST]:
    getAddedTrendChartSummaryRequest,
  [Types.GET_ADDED_TREND_CHART_SUMMARY_SUCCESS]:
    getAddedTrendChartSummarySuccess,

  [Types.GET_ADDED_TREND_CHART_FAILURE]: getAddedTrendChartFailure,
  [Types.GET_ADDED_TREND_CHART_REQUEST]: getAddedTrendChartRequest,
  [Types.GET_ADDED_TREND_CHART_SUCCESS]: getAddedTrendChartSuccess,

  [Types.GET_INSIGHTS_TREND_CHART_FAILURE]:
    getInsightsTrendChartFailure,
  [Types.GET_INSIGHTS_TREND_CHART_REQUEST]:
    getInsightsTrendChartRequest,
  [Types.GET_INSIGHTS_TREND_CHART_SUCCESS]:
    getInsightsTrendChartSuccess,

  [Types.GET_APPLICANTS_TREND_CHART_SUMMARY_FAILURE]:
    getApplicantsTrendChartSummaryFailure,
  [Types.GET_APPLICANTS_TREND_CHART_SUMMARY_REQUEST]:
    getApplicantsTrendChartSummaryRequest,
  [Types.GET_APPLICANTS_TREND_CHART_SUMMARY_SUCCESS]:
    getApplicantsTrendChartSummarySuccess,

  [Types.GET_APPLICANTS_TREND_CHART_FAILURE]:
    getApplicantsTrendChartFailure,
  [Types.GET_APPLICANTS_TREND_CHART_REQUEST]:
    getApplicantsTrendChartRequest,
  [Types.GET_APPLICANTS_TREND_CHART_SUCCESS]:
    getApplicantsTrendChartSuccess,

  [Types.GET_DASHBOARD_TREND_CHART_SUMMARY_FAILURE]:
    getDashboardTrendChartSummaryFailure,
  [Types.GET_DASHBOARD_TREND_CHART_SUMMARY_REQUEST]:
    getDashboardTrendChartSummaryRequest,
  [Types.GET_DASHBOARD_TREND_CHART_SUMMARY_SUCCESS]:
    getDashboardTrendChartSummarySuccess,
  [Types.GET_DASHBOARD_TREND_CHART_FAILURE]:
    getDashboardTrendChartFailure,
  [Types.GET_DASHBOARD_TREND_CHART_REQUEST]:
    getDashboardTrendChartRequest,
  [Types.GET_DASHBOARD_TREND_CHART_SUCCESS]:
    getDashboardTrendChartSuccess,

  [Types.GET_DASHBOARD_COUNTRY_WISE_CONNECTIONS_FAILURE]:
    getDashboardCountryWiseConnectionsFailure,
  [Types.GET_DASHBOARD_COUNTRY_WISE_CONNECTIONS_REQUEST]:
    getDashboardCountryWiseConnectionsRequest,
  [Types.GET_DASHBOARD_COUNTRY_WISE_CONNECTIONS_SUCCESS]:
    getDashboardCountryWiseConnectionsSuccess,

  [Types.APPLY_DASHBOARD_FILTERS_FAILURE]:
    applyDashboardFiltersFailure,
  [Types.APPLY_DASHBOARD_FILTERS_REQUEST]:
    applyDashboardFiltersRequest,

  [Types.RESET_DASHBOARD_FILTERS_FAILURE]:
    resetDashboardFiltersFailure,
  [Types.RESET_DASHBOARD_FILTERS_REQUEST]:
    resetDashboardFiltersRequest,

  [Types.DASHBOARD_CONTACT_UEM_FAILURE]: dashboardContactUemFailure,
  [Types.DASHBOARD_CONTACT_UEM_REQUEST]: dashboardContactUemRequest,
  [Types.DASHBOARD_CONTACT_UEM_SUCCESS]: dashboardContactUemSuccess,

  [Types.GET_USER_CONFIGURATION_FAILURE]: getUserConfigurationFailure,
  [Types.GET_USER_CONFIGURATION_REQUEST]: getUserConfigurationRequest,
  [Types.GET_USER_CONFIGURATION_SUCCESS]: getUserConfigurationSuccess,

  [Types.UPDATE_USER_CONFIGURATION_FAILURE]:
    updateUserConfigurationFailure,
  [Types.UPDATE_USER_CONFIGURATION_REQUEST]:
    updateUserConfigurationRequest,
  [Types.UPDATE_USER_CONFIGURATION_SUCCESS]:
    updateUserConfigurationSuccess,

  [Types.TOGGLE_DASHBOARD_REGION_EXPAND]: toggleDashboardRegionExpand,
  [Types.SET_DASHBOARD_REGION_CHECKBOX]: setDashboardRegionCheckbox,
  [Types.SET_DASHBOARD_COUNTRY_CHECKBOX]: setDashboardCountryCheckbox,
  [Types.DASHBOARD_FILTER_BADGE_CLICK]: dashboardFilterBadgeClick,
  [Types.SET_DASHBOARD_LAST_FILTERED_REGION_STATE]:
    setDashboardLastFilteredRegionState,
  [Types.SET_DASHBOARD_COUNTRY_WISE_CONNECTION_FILTER]:
    setDashboardCountryWiseConnectionFilter,

  [Types.UPDATE_DASHBOARD_ONBOARDING_STEP]:
    updateDashboardOnboardingStep,

  [Types.GET_UPCOMING_CALENDLY_EVENTS_FAILURE]:
    getUpcomingCalendlyEventsFailure,
  [Types.GET_UPCOMING_CALENDLY_EVENTS_REQUEST]:
    getUpcomingCalendlyEventsRequest,
  [Types.GET_UPCOMING_CALENDLY_EVENTS_SUCCESS]:
    getUpcomingCalendlyEventsSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
