import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getOnboardingSeedDataFailure: null,
  getOnboardingSeedDataRequest: ["userId"],
  getOnboardingSeedDataSuccess: ["onboardingSeedData"],

  getOnboardingDataFailure: null,
  getOnboardingDataRequest: ["userId"],
  getOnboardingDataSuccess: ["onboardingData"],

  getPeersFailure: null,
  getPeersRequest: ["userId"],
  getPeersSuccess: ["peersData"],

  saveOnboardingDataFailure: null,
  saveOnboardingDataRequest: ["userId", "data"],
  saveOnboardingDataSuccess: null,

  getFirstOnboardingStepsFailure: null,
  getFirstOnboardingStepsRequest: ["userId"],
  getFirstOnboardingStepsSuccess: ["onboardingFirstSteps"],

  saveFirstOnboardingStepsFailure: null,
  saveFirstOnboardingStepsRequest: ["userId", "data"],
  saveFirstOnboardingStepsSuccess: ["onboardingFirstSteps"],

  inviteUsersFailure: null,
  inviteUsersRequest: ["data"],
  inviteUsersSuccess: null,
});

export const OnboardingTypes = Types;
export const OnboardingCreators = Creators;
