import { createActions } from "reduxsauce";
import { PARAMS, DATA, RESOLVE, REJECT } from "./constants";

const ID = "id";
const REGIONS = "regions";
const TAB = "tab";

const TRENDCHARTDETAILS = "trendChartDetails";
const TRENDCHARTSUMMARY = "trendChartSummary";

const { Types, Creators } = createActions({
  clearDashboard: null,

  selectApplicationFunnelTab: [TAB],

  selectSplitsAndDimensionsTab: [TAB],

  getApplicationFunnelSummaryRequest: [PARAMS, RESOLVE, REJECT],
  getApplicationFunnelSummaryFailure: null,
  getApplicationFunnelSummarySuccess: [DATA],

  getSplitsConnectionInsightsRequest: [PARAMS, RESOLVE, REJECT],
  getSplitsConnectionInsightsFailure: null,
  getSplitsConnectionInsightsSuccess: [DATA],

  getSplitsListingRequest: [PARAMS, RESOLVE, REJECT],
  getSplitsListingFailure: null,
  getSplitsListingSuccess: [DATA],

  getSplitsProfileRequest: [PARAMS, RESOLVE, REJECT],
  getSplitsProfileFailure: null,
  getSplitsProfileSuccess: [DATA],

  getSplitsEventsRequest: [PARAMS, RESOLVE, REJECT],
  getSplitsEventsFailure: null,
  getSplitsEventsSuccess: [DATA],

  getSplitsVisitsRequest: [PARAMS, RESOLVE, REJECT],
  getSplitsVisitsFailure: null,
  getSplitsVisitsSuccess: [DATA],

  getRecommendedSchoolsToVisitRequest: [PARAMS, RESOLVE, REJECT],
  getRecommendedSchoolsToVisitFailure: null,
  getRecommendedSchoolsToVisitSuccess: [DATA],

  getRecommendedSchoolsToVisitPastRequest: [PARAMS, RESOLVE, REJECT],
  getRecommendedSchoolsToVisitPastFailure: null,
  getRecommendedSchoolsToVisitPastSuccess: [DATA],

  getAddedToListRecommendedSchoolsToVisitRequest: [
    PARAMS,
    RESOLVE,
    REJECT,
  ],
  getAddedToListRecommendedSchoolsToVisitFailure: null,
  getAddedToListRecommendedSchoolsToVisitSuccess: [DATA],

  getApplicantsRecommendedSchoolsToVisitRequest: [
    PARAMS,
    RESOLVE,
    REJECT,
  ],
  getApplicantsRecommendedSchoolsToVisitFailure: null,
  getApplicantsRecommendedSchoolsToVisitSuccess: [DATA],

  getDashboardEventsRequest: [PARAMS, RESOLVE, REJECT],
  getDashboardEventsFailure: null,
  getDashboardEventsSuccess: [DATA],

  getNewConnectionsRequest: [PARAMS, RESOLVE, REJECT],
  getNewConnectionsFailure: null,
  getNewConnectionsSuccess: [DATA],

  getAddedToListNewActivitiesRequest: [PARAMS, RESOLVE, REJECT],
  getAddedToListNewActivitiesFailure: null,
  getAddedToListNewActivitiesSuccess: [DATA],

  getApplicantsNewActivitiesRequest: [PARAMS, RESOLVE, REJECT],
  getApplicantsNewActivitiesFailure: null,
  getApplicantsNewActivitiesSuccess: [DATA],

  getDashboardRegionsFailure: null,
  getDashboardRegionsRequest: [RESOLVE, REJECT],
  getDashboardRegionsSuccess: [REGIONS],

  getDashboardSelectedRegionsFailure: null,
  getDashboardSelectedRegionsRequest: [RESOLVE, REJECT],
  getDashboardSelectedRegionsSuccess: ["selected_regions"],

  getDashboardTrendChartSummaryFailure: null,
  getDashboardTrendChartSummaryRequest: [RESOLVE, REJECT],
  getDashboardTrendChartSummarySuccess: [TRENDCHARTSUMMARY],

  getDashboardTrendChartFailure: null,
  getDashboardTrendChartRequest: [RESOLVE, REJECT],
  getDashboardTrendChartSuccess: [TRENDCHARTDETAILS],

  getAddedTrendChartSummaryFailure: null,
  getAddedTrendChartSummaryRequest: [RESOLVE, REJECT],
  getAddedTrendChartSummarySuccess: [TRENDCHARTSUMMARY],

  getAddedTrendChartFailure: null,
  getAddedTrendChartRequest: [RESOLVE, REJECT],
  getAddedTrendChartSuccess: [TRENDCHARTDETAILS],

  getInsightsTrendChartFailure: null,
  getInsightsTrendChartRequest: [RESOLVE, REJECT],
  getInsightsTrendChartSuccess: [TRENDCHARTDETAILS],

  getApplicantsTrendChartSummaryFailure: null,
  getApplicantsTrendChartSummaryRequest: [RESOLVE, REJECT],
  getApplicantsTrendChartSummarySuccess: [TRENDCHARTSUMMARY],

  getApplicantsTrendChartFailure: null,
  getApplicantsTrendChartRequest: [RESOLVE, REJECT],
  getApplicantsTrendChartSuccess: [TRENDCHARTDETAILS],

  getDashboardCountryWiseConnectionsFailure: null,
  getDashboardCountryWiseConnectionsRequest: [TAB, RESOLVE, REJECT],
  getDashboardCountryWiseConnectionsSuccess: ["connections"],

  applyDashboardFiltersFailure: null,
  applyDashboardFiltersRequest: [RESOLVE, REJECT],
  applyDashboardFiltersSuccess: null,

  resetDashboardFiltersFailure: null,
  resetDashboardFiltersRequest: [RESOLVE, REJECT],
  resetDashboardFiltersSuccess: null,

  dashboardContactUemFailure: null,
  dashboardContactUemRequest: [
    ID,
    "requestType",
    "message",
    RESOLVE,
    REJECT,
  ],
  dashboardContactUemSuccess: null,

  getUserConfigurationFailure: null,
  getUserConfigurationRequest: [RESOLVE, REJECT],
  getUserConfigurationSuccess: ["config"],

  updateUserConfigurationFailure: null,
  updateUserConfigurationRequest: [
    "isDashboardOnboardingComplete",
    RESOLVE,
    REJECT,
  ],
  updateUserConfigurationSuccess: null,

  toggleDashboardRegionExpand: [ID],
  setDashboardRegionCheckbox: [ID],
  setDashboardCountryCheckbox: [
    "regionId",
    "countryId",
    "isSelected",
  ],
  setDashboardLastFilteredRegionState: null,
  dashboardFilterBadgeClick: [ID],
  setDashboardCountryWiseConnectionFilter: ["filterBy"],
  updateDashboardOnboardingStep: ["stepAction"],

  getUpcomingCalendlyEventsFailure: null,
  getUpcomingCalendlyEventsRequest: null,
  getUpcomingCalendlyEventsSuccess: ["calendlyEvents"],
});

export const DashboardTypes = Types;
export const DashboardCreators = Creators;
