import axios from "axios";
import merge from "lodash/merge";
import join from "lodash/join";
import map from "lodash/map";
import isString from "lodash/isString";
import i18n from "src/i18n";
import { APP_TOKEN } from "src/config";
import EventBus from "eventing-bus";
import { Notification } from "src/helpers/notification";

const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NOT_FOUND = 404;

const clientErrors = [
  BAD_REQUEST,
  UNAUTHORIZED,
  FORBIDDEN,
  NOT_FOUND,
];

const expireSession = () => {
  EventBus.publish("session_expired");
  Notification.error(i18n.t("alerts.failure.session_expired"));
};

const handleClientErrors = (status, data = {}) => {
  const { error } = data;

  if (status === UNAUTHORIZED) {
    return expireSession();
  }

  let errorMessage = i18n.t("alerts.failure.unknown");

  if (status === NOT_FOUND && error?.email) {
    errorMessage = error.email;
  } else if (status === FORBIDDEN && error?.error_message) {
    errorMessage = error.error_message;
  } else if (error) {
    errorMessage = isString(error) ? error : "Record not found.";
  }
  return Notification.error(errorMessage);
};

const handleRemainingErrors = (data = {}) => {
  const { errors, error } = data;
  let errorMessages = "";

  if (errors) {
    errorMessages = join(errors, "\n");
  } else if (error) {
    if (typeof error === "string") {
      errorMessages = error;
    } else if (error instanceof Object) {
      errorMessages = join(
        map(error, message => message),
        "\n",
      );
    }
  }

  return Notification.error(errorMessages);
};

export class API {
  static headers() {
    return {
      "Content-Type": "application/json",
      app_token: APP_TOKEN,
    };
  }

  static request(options) {
    if (options.mergeHeaders !== false)
      options.headers = merge(this.headers(), options.headers);

    return axios(options).catch(error => {
      if (options.silent !== true) {
        if (error.response) {
          const {
            response: { status, data },
          } = error;
          const customMessagesForCodes =
            options.customMessagesForCodes &&
            options.customMessagesForCodes[status];
          if (customMessagesForCodes) {
            Notification.error(customMessagesForCodes);
          } else if (clientErrors.includes(status)) {
            handleClientErrors(status, data);
          } else {
            handleRemainingErrors(data);
          }
        } else if (error.message === "Network Error") {
          Notification.error(
            i18n.t("alerts.failure.internet_connection"),
          );
        } else {
          Notification.error(i18n.t("alerts.failure.unknown"));
        }
      }

      throw error;
    });
  }
}
